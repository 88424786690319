
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        cid: 0
      }
    },
    // mounted() {
    //   let menu = this.defaultMenu
    //   if (this.menu) {
    //     menu = this.menu
    //   }
    //   menu.categories.forEach((category,i) => {
    //     // TODO: select appropriate category (favorites)
    //     if (i == 4) {
    //       category.products.forEach((item,i2) => {
    //         if (i2 < 4) {
    //           this.products.push(item)
    //         }
    //       })
    //     }
    //   })
    // },
    computed: {
      ...mapState(['menu','defaultMenu','user','homepageTopAlertContentCards', 'activeOrder']),
      favorites() {
        let menu = this.defaultMenu
        if (this.menu) {
          menu = this.menu
        }
        let favorites = []
        menu?.categories.forEach((category,i) => {
          // TODO: select appropriate category (favorites)
          if (i == 4) {
            this.cid = category.id
            category.products.forEach((item,i2) => {
              if (i2 < 4) {
                favorites.push(item)
              }
            })
          }
        })
        return favorites
      }
    },
    methods: {
      getCals(product) {
        if (product.calories.base && product.calories.max) {
          return product.calories.base+'-'+product.calories.max+' Cal'
        } else if (product.calories.base) {
          return product.calories.base+' Cal'
        } else if (product.calories.max) {
          return product.calories.max+' Cal'
        }
      }
    }
  }
